<template>
  <div class="page-products-photos">
    <back />
    <n-loader :loading="$var('load')" />

    <swiper ref="swiperTop" class="swiper gallery-top" :options="swiperOptionTop">
      <swiper-slide v-for="image in images" :key="image.id">
        <img :src="image.src" alt="">
      </swiper-slide>
    </swiper>

    <swiper ref="swiperThumbs" class="swiper gallery-thumbs" :options="swiperOptionThumbs">
      <swiper-slide v-for="image in images" :key="image.id">
        <img :src="image.src" alt="">
      </swiper-slide>
    </swiper>
    <div slot="pagination" class="swiper-pagination"></div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide, } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: 'PageProductsPhotos',
  components: { Swiper, SwiperSlide, },
  data() {
    return {
      swiperOptionTop: {
        loop: true,
        spaceBetween: 10,
      },
      swiperOptionThumbs: {
        loop: true,
        spaceBetween: 10,
        slidesPerView: 'auto',
        touchRatio: 0.2,
        slideToClickedSlide: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
      images: [],
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    photoId() {
      return this.$route.params.photoId
    },
  },
  mounted() {
    this.$nextTick(() => {
      const swiperTop = this.$refs.swiperTop.$swiper
      const swiperThumbs = this.$refs.swiperThumbs.$swiper
      swiperTop.controller.control = swiperThumbs
      swiperThumbs.controller.control = swiperTop
    })
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.$var('load', true)
      $api.app.products.get(this.id).with('images').then((response) => {
        this.images = response.data.content.images
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style scoped lang="scss">
.page-products-photos {
  padding-bottom: 50px;

  .swiper {
    .swiper-slide {
      background-size: cover;
      background-position: center;

      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &.gallery-top {
      height: calc(100vh - 300px);
      width: 100%;
      margin-bottom: 22px;
    }
    &.gallery-thumbs {
      height: 90px;
      box-sizing: border-box;
      padding: 0;
    }
    &.gallery-thumbs .swiper-slide {
      width: 33.33333%;
      height: 100%;
      opacity: 0.4;
    }
    &.gallery-thumbs .swiper-slide-active {
      opacity: 1;
    }
  }

  &::v-deep .swiper-pagination{
    position: relative;
    margin-top: 10px;
  }

  &::v-deep .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 0;
  }

  &::v-deep .swiper-pagination-bullet {
    background: #EBEBEB;
    opacity: 1;
    transition: background .2s ease;
    width: 10px;
    height: 10px;
    margin: 0 3px;
  }

  &::v-deep .swiper-pagination-bullet-active {
    background: #169401;
  }
}
</style>
